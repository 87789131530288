import React from 'react';
import ReactDOM from 'react-dom/client';
import { Store } from '@ludens-reklame/rubics-v3-sdk/store/types';
import { Section } from '@ludens-reklame/rubics-v3-sdk/section/types';
import { App } from './app';

const Header = React.lazy(() => import('./components/Header/Header.tsx'));
const Text = React.lazy(() => import('./components/Text/Text.tsx'));
const Hero = React.lazy(() => import('./components/Hero/Hero.tsx'));
const Banner = React.lazy(() => import('./components/Banner/Banner.tsx'));
const CTABanner = React.lazy(
  () => import('./components/CTABanner/CTABanner.tsx')
);
const Cards = React.lazy(() => import('./components/Cards/Cards.tsx'));
const ExpandableSections = React.lazy(
  () => import('./components/ExpandableSections/ExpandableSections.tsx')
);
const Infographic = React.lazy(
  () => import('./components/Infographic/Infographic.tsx')
);
const Form = React.lazy(() => import('./components/Form/Form.tsx'));
const ImageGrid = React.lazy(
  () => import('./components/ImageGrid/ImageGrid.tsx')
);
const StatusBox = React.lazy(
  () => import('./components/StatusBox/StatusBox.tsx')
);

const LogoCarousel = React.lazy(
  () => import('./components/LogoCarousel/LogoCarousel.tsx')
);

const SearchResults = React.lazy(
  () => import('./components/SearchResults/SearchResults.tsx')
);

const SpeedCalculator = React.lazy(
  () => import('./components/SpeedCalculator/SpeedCalculator.tsx')
);

const EmbedGrid = React.lazy(
  () => import('./components/EmbedGrid/EmbedGrid.tsx')
);
const VideoGrid = React.lazy(
  () => import('./components/VideoGrid/videoGrid.tsx')
);
const VideoAndText = React.lazy(
  () => import('./components/VideoAndText/videoAndText.tsx')
);

const Employees = React.lazy(
  () => import('./components/Employees/Employees.tsx')
);

const Product = React.lazy(() => import('./components/Product/Product.tsx'));
const Products = React.lazy(() => import('./components/Products/Products.tsx'));
const Cart = React.lazy(() => import('./components/Cart/Cart.tsx'));
const Checkout = React.lazy(() => import('./components/Checkout/Checkout.tsx'));
const MyPage = React.lazy(() => import('./components/MyPage/MyPage.tsx'));
const Footer = React.lazy(() => import('./components/Footer/Footer.tsx'));
const Video = React.lazy(() => import('./components/Video/video.tsx'));

declare var _STORE: Store;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App store={_STORE} renderer={renderSection} />
  </React.StrictMode>
);

function renderSection(
  section: Section,
  children?: React.ReactNode
): React.ReactNode {
  let suspend: boolean = true;
  let Component: React.ComponentType<any>;
  const domElem = document.getElementById(section.name);

  switch (section.component) {
    case 'Header':
      Component = Header;
      suspend = false;
      break;
    case 'Text':
      Component = Text;
      break;
    case 'Hero':
      Component = Hero;
      break;
    case 'Banner':
      Component = Banner;
      break;
    case 'CTABanner':
      Component = CTABanner;
      break;
    case 'Cards':
      Component = Cards;
      break;
    case 'ExpandableSections':
      Component = ExpandableSections;
      break;
    case 'Infographic':
      Component = Infographic;
      break;
    case 'Form':
      Component = Form;
      break;
    case 'ImageGrid':
      Component = ImageGrid;
      break;
    case 'StatusBox':
      Component = StatusBox;
      break;
    case 'LogoCarousel':
      Component = LogoCarousel;
      break;
    case 'SearchResults':
      Component = SearchResults;
      break;
    case 'SpeedCalculator':
      Component = SpeedCalculator;
      break;
    case 'EmbedGrid':
      Component = EmbedGrid;
      break;
    case 'Product':
      Component = Product;
      break;
    case 'Products':
      Component = Products;
      break;
    case 'Cart':
      Component = Cart;
      break;
    case 'Checkout':
      Component = Checkout;
      break;
    case 'MyPage':
      Component = MyPage;
      break;
    case 'Footer':
      Component = Footer;
      suspend = false;
      break;
    case 'Video':
      Component = Video;
      break;
    case 'VideoGrid':
      Component = VideoGrid;
      break;
    case 'VideoAndText':
      Component = VideoAndText;
      break;
    case 'Employees':
      Component = Employees;
      break;
    default:
      return null;
  }

  if (!suspend) {
    return <Component {...section.props}>{children}</Component>;
  }

  return (
    <React.Suspense
      fallback={
        domElem ? (
          <div dangerouslySetInnerHTML={{ __html: domElem.innerHTML }} />
        ) : null
      }
    >
      {/* @ts-ignore */}
      <Component {...section.props}>{children}</Component>
    </React.Suspense>
  );
}
