import { ComponentRenderer, Page } from '@ludens-reklame/rubics-v3-react/page';
import { Store } from '@ludens-reklame/rubics-v3-sdk/store/types';

export const App: React.FC<{ store: Store; renderer: ComponentRenderer }> = ({
  store,
  renderer
}) => {
  return (
    <Page store={store} renderComponent={renderer} useCartContext={true}>
      {({ header, main, footer }) => (
        <>
          {header}
          <main>{main}</main>
          {footer}
        </>
      )}
    </Page>
  );
};
